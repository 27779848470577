import React, { useState, useEffect, useRef } from "react";
import { BsFillPersonDashFill } from "react-icons/bs";
import {
  FaUserFriends,
  FaMobileAlt,
  FaUserSecret,
  FaUserSlash,
  FaFileCsv,
  FaChartPie,
  FaClipboardList,
  FaUserNinja,
  FaLaptop,
  FaUserCheck,
  FaCameraRetro,
  FaLock,
  FaUnlock,
} from "react-icons/fa";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import ReactPaginate from "react-paginate";
import jwt from "jsonwebtoken";
import raw from "raw.macro";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import NavBar from "../navBar/navBar";
// import httpService from "../../services/httpService";

// import Modal from "react-modal";

import "./table.css";
import axios from "axios";
import PieChart from "../pieChart/pieChart";
import BarChart from "../barChart/barChart";
import Tabel2 from "../userDataTable/tabel2";
import { useHistory } from "react-router-dom";
import { array } from "@amcharts/amcharts4/core";

const Table = () => {
  const history = useHistory();
  //for export and charts with filters snd counters using filters
  const [unpagenatedData, setUnpagenatedData] = useState([]);
  const [unpagenatedDatapageNumber, setUnpaginatedPageNumber] = useState(0);
  const [unpagenatedDatapageSize, setUnpaginatedPageSize] = useState(250);
  const [unpagenatedDatatotalCount, setUnpaginatedTotalCount] = useState(0);
  const [
    currentUnpagenatedDataPagestotalCount,
    setcurrentUnpagenatedDataPagestotalCount,
  ] = useState(0);
  //all paginated data
  const [data, setData] = useState([]);
  const [isLocked, setIsLocked] = useState(false);
  const [isLockedID, setIsLockedID] = useState();

  const [statsPage, setStatsPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);

  const [RightUser, setRightUser] = useState(0);
  const [WrongUser, setWrongUser] = useState(0);
  const [PersonBody, setPersonBody] = useState(0);
  const [MultipleUser, setMultipleUser] = useState(0);
  const [NoUser, setNoUser] = useState(0);

  const [PhoneDetected, setPhoneDetected] = useState(0);
  const [LockedCamera, setLockedCamera] = useState(0);
  const [BlockedCamera, setBlockedCamera] = useState(0);

  const [UnrecognizedFace, setUnrecognizedFace] = useState(0);
  const [LockedLaptop, setLockedLaptop] = useState(0);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const searchRef = useRef("");
  const selectRef = useRef("");

  const privateKey = raw(`./private.json`);
  const [state] = useState({
    email: "webPortal@vodafone.com",
  });
  var token = jwt.sign(state, privateKey);
  // console.log("tokeen", token);
  const headers = {
    authorization: token,
  };

  // use effect hook
  useEffect(() => {
    console.log("Test Pipline");
    // axios to get all
    // setData([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: pageNumber,
          pageCount: pageSize,
          to,
          from,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log("first api call", res.data);
        setData(res?.data?.list);
        setTotalCount(res?.data?.paging?.totalCount);
        setPageNumber(res?.data?.paging?.nextPageNumber);

        // axios
        //   .get(`${process.env.REACT_APP_SERVER_URL}violations/counts`)
        //   .then((res) => {
        //     setWrongUser(
        //       res.data.filter((r) => r.label == "wrong_user")[0].count
        //     );
        //     setMultipleUser(
        //       res.data.filter((r) => r.label == "multiple_users")[0].count
        //     );
        //     setNoUser(res.data.filter((r) => r.label == "no_user")[0].count);
        //     setPhoneDetected(
        //       res.data.filter((r) => r.label == "phone_detected")[0].count
        //     );

        //     setLockedCamera(
        //       res.data.filter((r) => r.label == "locked_camera")[0].count
        //     );
        //     setUnrecognizedFace(
        //       res.data.filter((r) => r.label == "unrecognized_face_detected")[0]
        //         .count
        //     );
        //     setLockedLaptop(
        //       res.data.filter((r) => r.label == "locked_laptop")[0].count
        //     );
        //     setIsLoading(false);
        //   });
        setIsLoading(false);
      });

    //setFilteredDataWithoutPagination([])

    // axios
    //   .post(
    //     `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
    //     {
    //       searchText: searchRef.current.value,
    //       Type: selectRef.current.value,
    //       page: 0,
    //       pageCount: unpagenatedDatapageSize,
    //       to,
    //       from,
    //     },
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then((res) => {
    //     // console.log("first api call", res.data);
    //     setUnpaginatedPageNumber(res.data.paging.nextPageNumber);
    //     setUnpaginatedTotalCount(res.data.paging.totalCount);
    //     console.log(res.data.paging.nextPageNumber);
    //     let pageCount = Math.ceil(
    //       res.data.paging.totalCount / unpagenatedDatapageSize
    //     );
    //     setcurrentUnpagenatedDataPagestotalCount(pageCount);

    //     for (let index = 0; index < pageCount; index++) {
    //       axios
    //         .post(
    //           `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
    //           {
    //             searchText: searchRef.current.value,
    //             Type: selectRef.current.value,
    //             page: index,
    //             pageCount: unpagenatedDatapageSize,
    //             to,
    //             from,
    //           },
    //           {
    //             headers: headers,
    //           }
    //         )
    //         .then((res) => {
    //           let x = unpagenatedData;
    //           res.data.list.forEach((element) => {
    //             x.push(element);
    //           });
    //           setUnpagenatedData(x);
    //         });
    //     }
    //     axios
    //       .get(`${process.env.REACT_APP_SERVER_URL}violations/counts`)
    //       .then((res) => {
    //         console.log(res.data.filter((r) => r.label == "right_user"));

    //         setWrongUser(
    //           res.data.filter((r) => r.label == "wrong_user")[0].count
    //         );

    //         setMultipleUser(
    //           res.data.filter((r) => r.label == "multiple_users")[0].count
    //         );
    //         setNoUser(res.data.filter((r) => r.label == "no_user")[0].count);
    //         setPhoneDetected(
    //           res.data.filter((r) => r.label == "phone_detected")[0].count
    //         );

    //         setLockedCamera(
    //           res.data.filter((r) => r.label == "locked_camera")[0].count
    //         );
    //         setUnrecognizedFace(
    //           res.data.filter((r) => r.label == "unrecognized_face_detected")[0]
    //             .count
    //         );
    //         setLockedLaptop(
    //           res.data.filter((r) => r.label == "locked_laptop")[0].count
    //         );
    //       });
    //     setIsLoading(false);
    //     setUnpaginatedPageNumber(0);
    //   });
  }, []);

  let violationType = [];

  violationType = [
    { value: "", label: "all" },
    { value: "right_user", label: "Right user" },
    { value: "wrong_user", label: "Wrong user" },
    { value: "multiple_users", label: "Multiple users" },
    { value: "phone_detected", label: "Phone detected" },
    { value: "person_body_detected", label: "Person body detected" },
    { value: "no_user", label: "No user" },
    {
      value: "locked_camera",
      label: "Locked camera",
    },
    {
      value: "blocked_camera",
      label: "Blocked camera",
    },
    {
      value: "unrecognized_face_detected",
      label: "Unrecognized face detected",
    },
    {
      value: "locked_laptop",
      label: "Locked laptop",
    },
  ];

  const handleClearFilters = () => {
    searchRef.current.value = "";
    selectRef.current.value = "";
    setFrom("");
    setTo("");
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: 0,
          pageCount: pageSize,
          to,
          from,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });

    // axios
    //   .post(
    //     `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
    //     {
    //       searchText: searchRef.current.value,
    //       Type: selectRef.current.value,
    //       page: 0,
    //       pageCount: unpagenatedDatapageSize,
    //       to,
    //       from,
    //     },
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then((res) => {
    //     // console.log("first api call", res.data);
    //     setUnpaginatedPageNumber(res.data.paging.nextPageNumber);
    //     setUnpaginatedTotalCount(res.data.paging.totalCount);
    //     console.log(res.data.paging.nextPageNumber);
    //     let pageCount = Math.ceil(
    //       res.data.paging.totalCount / unpagenatedDatapageSize
    //     );
    //     setcurrentUnpagenatedDataPagestotalCount(pageCount);

    //     for (let index = 0; index < pageCount; index++) {
    //       axios
    //         .post(
    //           `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
    //           {
    //             searchText: searchRef.current.value,
    //             Type: selectRef.current.value,
    //             page: index,
    //             pageCount: unpagenatedDatapageSize,
    //             to,
    //             from,
    //           },
    //           {
    //             headers: headers,
    //           }
    //         )
    //         .then((res) => {
    //           let x = unpagenatedData;
    //           res.data.list.forEach((element) => {
    //             x.push(element);
    //           });
    //           setUnpagenatedData(x);
    //         });
    //     }
    //     axios
    //       .get(`${process.env.REACT_APP_SERVER_URL}violations/counts`)
    //       .then((res) => {
    //         console.log(res.data.filter((r) => r.label == "right_user"));

    //         setWrongUser(
    //           res.data.filter((r) => r.label == "wrong_user")[0].count
    //         );

    //         setMultipleUser(
    //           res.data.filter((r) => r.label == "multiple_users")[0].count
    //         );
    //         setNoUser(res.data.filter((r) => r.label == "no_user")[0].count);
    //         setPhoneDetected(
    //           res.data.filter((r) => r.label == "phone_detected")[0].count
    //         );

    //         setLockedCamera(
    //           res.data.filter((r) => r.label == "locked_camera")[0].count
    //         );
    //         setUnrecognizedFace(
    //           res.data.filter((r) => r.label == "unrecognized_face_detected")[0]
    //             .count
    //         );
    //         setLockedLaptop(
    //           res.data.filter((r) => r.label == "locked_laptop")[0].count
    //         );
    //       });
    //     setIsLoading(false);
    //     setUnpaginatedPageNumber(0);
    //   });
  };

  // const downloadExcel = () => {
  //   const data = unpagenatedData.map((record) => {
  //     let obj = {};
  //     for (let key in record) {
  //       if (
  //         _.startCase(key) != "Id" ||
  //         _.startCase(key) != "User Email" ||
  //         _.startCase(key) != "Manager Email"
  //       ) {
  //         obj[_.startCase(key)] = record[key];
  //       }
  //       // Change key letter case to start case for better excel
  //       if (key == "violationDate") {
  //         obj[_.startCase(key)] = new Date(record[key]).toDateString();
  //       }
  //     }

  //     return obj;
  //   });
  //   const ws = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
  //   const wb = (XLSX.WorkBook = XLSX.utils.book_new());
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // var staffId = +localStorage.getItem('StaffId');

  //   /* save to file */
  //   XLSX.writeFile(wb, "Violations_" + new Date() + ".xlsx");
  // };
  const handleSearch = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: 0,
          pageCount: pageSize,
          to,
          from,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log("searched data", res.data);
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);

        setPageNumber(res.data.paging.nextPageNumber);
        setLoading(false);
      });
    // axios
    //   .post(
    //     `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
    //     {
    //       searchText: searchRef.current.value,
    //       Type: selectRef.current.value,
    //       page: 0,
    //       pageCount: unpagenatedDatapageSize,
    //       to,
    //       from,
    //     },
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then((res) => {
    //     // console.log("first api call", res.data);
    //     setUnpaginatedPageNumber(res.data.paging.nextPageNumber);
    //     setUnpaginatedTotalCount(res.data.paging.totalCount);
    //     console.log(res.data.paging.nextPageNumber);
    //     let pageCount = Math.ceil(
    //       res.data.paging.totalCount / unpagenatedDatapageSize
    //     );
    //     setcurrentUnpagenatedDataPagestotalCount(pageCount);

    //     for (let index = 0; index < pageCount; index++) {
    //       axios
    //         .post(
    //           `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
    //           {
    //             searchText: searchRef.current.value,
    //             Type: selectRef.current.value,
    //             page: index,
    //             pageCount: unpagenatedDatapageSize,
    //             to,
    //             from,
    //           },
    //           {
    //             headers: headers,
    //           }
    //         )
    //         .then((res) => {
    //           let x = unpagenatedData;
    //           res.data.list.forEach((element) => {
    //             x.push(element);
    //           });
    //           setUnpagenatedData(x);
    //         });
    //     }
    //     axios
    //       .get(`${process.env.REACT_APP_SERVER_URL}violations/counts`)
    //       .then((res) => {
    //         console.log(res.data.filter((r) => r.label == "right_user"));

    //         setWrongUser(
    //           res.data.filter((r) => r.label == "wrong_user")[0].count
    //         );

    //         setMultipleUser(
    //           res.data.filter((r) => r.label == "multiple_users")[0].count
    //         );
    //         setNoUser(res.data.filter((r) => r.label == "no_user")[0].count);
    //         setPhoneDetected(
    //           res.data.filter((r) => r.label == "phone_detected")[0].count
    //         );

    //         setLockedCamera(
    //           res.data.filter((r) => r.label == "locked_camera")[0].count
    //         );
    //         setUnrecognizedFace(
    //           res.data.filter((r) => r.label == "unrecognized_face_detected")[0]
    //             .count
    //         );
    //         setLockedLaptop(
    //           res.data.filter((r) => r.label == "locked_laptop")[0].count
    //         );
    //       });
    //     setIsLoading(false);
    //     setUnpaginatedPageNumber(0);
    //   });
    // const data = unpagenatedData.map((record) => {
    //   let obj = {};
    //   for (let key in record) {
    //     obj[_.startCase(key)] = record[key];
    //     // Change key letter case to start case for better excel
    //     if (key == "violationDate") {
    //       obj[_.startCase(key)] = new Date(record[key]).toDateString();
    //     }
    //   }

    //   return obj;
    // });
  };

  const handlePaginationClick = (e) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}violations/infosecpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: e.selected,
          pageCount: pageSize,
          to,
          from,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };
  if (isLoading) {
    return (
      <div>
        <div className='spinner-grow text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-secondary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-success' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-danger' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-warning' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-info' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-dark' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-light' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <>
      {/* <NavBar role='admin' /> */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-10'>
            <div className='row ml-3 searchRectangle'>
              <div
                className='col-md-3 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  NT Code
                </label>
                <input
                  type='text'
                  className=' filter-text p-2'
                  // onChange={(event) => handleSearchFilter(event)}
                  autoComplete='on'
                  style={{ height: "2.3rem" }}
                  ref={searchRef}
                />
              </div>
              <div
                className='col-md-3 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  Violation Type
                </label>
                <select
                  ref={selectRef}
                  className='filter-text '
                  style={{ padding: "0.35rem " }}
                >
                  {violationType.map((r) => (
                    <option value={r.value}>{r.label}</option>
                  ))}
                </select>
              </div>{" "}
              <div
                className='col-md-3 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  From
                </label>
                <DatePicker
                  selected={from}
                  onChange={(date) => setFrom(date)}
                />
              </div>
              <div
                className='col-md-3 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  To
                </label>
                <DatePicker selected={to} onChange={(date) => setTo(date)} />
              </div>
              <div className='col-md-12 pt-5'>
                <div className='row'>
                  <label
                    style={{
                      opacity: "0.7",
                      fontWeight: "bold",

                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={handleClearFilters}
                    className='col-md-6'
                  >
                    Clear Filters
                  </label>
                  <div
                    className='col-md-6'
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {loading ? (
                      <div class='spinner-border' role='status'>
                        <span class='visually-hidden'></span>
                      </div>
                    ) : (
                      <button
                        style={{
                          fontWeight: "bold",
                          width: "30%",
                          background: "red",
                          color: "white",

                          borderRadius: "10px",
                        }}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* <div className='row Counters'>
           <div className='backgroundOne col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaUserCheck class='material-icons-requests'></FaUserCheck>
              </div>

              <div className='col-md-7'>
                <p className='number'>{RightUser}</p>
                <p className='canceled-requests'>Right user</p>
              </div>
            </div>
          </div> 
          <div className='backgroundTwo col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <BsFillPersonDashFill class='material-icons-requests'></BsFillPersonDashFill>
              </div>

              <div className='col-md-7'>
                <p className='number'>{WrongUser}</p>
                <p className='canceled-requests'>Wrong user</p>
              </div>
            </div>
          </div>
          <div className='backgroundThree col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaUserFriends className='material-icons-requests'></FaUserFriends>
              </div>

              <div className='col-md-7'>
                <p className='number'>{MultipleUser}</p>
                <p className='canceled-requests'>Multiple users</p>
              </div>
            </div>
          </div>
          <div className='backgroundFour col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaMobileAlt class='material-icons-requests'></FaMobileAlt>
              </div>

              <div className='col-md-7'>
                <p className='number'>{PhoneDetected}</p>
                <p className='canceled-requests'>Phone detected</p>
              </div>
            </div>
          </div>
           <div className='backgroundFive col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaUserSecret className='material-icons-requests'></FaUserSecret>
              </div>

              <div className='col-md-7'>
                <p className='number'>{PersonBody}</p>
                <p className='canceled-requests'>Person body detected</p>
              </div>
            </div>
          </div> 
          <div className='backgroundSix col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaUserSlash className='material-icons-requests'></FaUserSlash>
              </div>

              <div className='col-md-7'>
                <p className='number'>{NoUser}</p>
                <p className='canceled-requests'>No user</p>
              </div>
            </div>
          </div>
          <div className='backgroundSeven col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaUserNinja className='material-icons-requests'></FaUserNinja>
              </div>

              <div className='col-md-7'>
                <p className='number'>{UnrecognizedFace}</p>
                <p className='canceled-requests'>Unrecognized face detected</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row Counters'>
          <div className='backgroundeight col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaLaptop className='material-icons-requests'></FaLaptop>
              </div>

              <div className='col-md-7'>
                <p className='number'>{LockedLaptop}</p>
                <p className='canceled-requests'>Locked laptop</p>
              </div>
            </div>
          </div>
          <div className='backgroundnine col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaCameraRetro className='material-icons-requests'></FaCameraRetro>
              </div>

              <div className='col-md-7'>
                <p className='number'>{LockedCamera}</p>
                <p className='canceled-requests'>Locked camera</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className='tableclass'>
          <div className='row mb-3 mt-3 ml-3 '>
            <h2 className='col-md-6'>Violation List</h2>
            <div
              className='col-md-6'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <button
                type='button'
                onClick={() => history.push("/machinestatus")}
                className='btn btn-outline-dark mr-3'
              >
                View Machines Status
              </button>
              {/* {statsPage ? (
                <button
                  type='button'
                  onClick={() => {
                    setStatsPage(false);
                  }}
                  className='btn btn-outline-dark mr-2'
                >
                  <FaClipboardList className='mr-2'></FaClipboardList>
                  List View
                </button>
              ) : (
                <button
                  type='button'
                  onClick={() => {
                    setStatsPage(true);
                  }}
                  className='btn btn-outline-dark mr-2'
                >
                  <FaChartPie className='mr-2'></FaChartPie>
                  Statistics View
                </button>
              )} */}

              {/* <button
                type='button'
                onClick={downloadExcel}
                className='btn btn-outline-dark mr-3'
              >
                <FaFileCsv className='mr-2'></FaFileCsv>
                Export Violations
              </button> */}
            </div>
          </div>
          <div className='row mt-3 ml-3 mr-3'>
            {statsPage ? (
              unpagenatedData.filter((r) => r.violationType === "wrong_user")
                .length === 0 &&
              unpagenatedData.filter(
                (r) => r.violationType === "multiple_users"
              ).length === 0 &&
              unpagenatedData.filter(
                (r) => r.violationType === "phone_detected"
              ).length === 0 &&
              unpagenatedData.filter(
                (r) => r.violationType === "person_body_detected"
              ).length === 0 &&
              unpagenatedData.filter((r) => r.violationType === "no_user")
                .length === 0 &&
              unpagenatedData.filter(
                (r) => r.violationType === "unrecognized_face_detected"
              ).length === 0 &&
              unpagenatedData.filter((r) => r.violationType === "locked_laptop")
                .length === 0 &&
              unpagenatedData.filter((r) => r.violationType === "locked_camera")
                .length === 0 &&
              unpagenatedData.filter(
                (r) => r.violationType === "blocked_camera"
              ).length === 0 &&
              unpagenatedData.filter((r) => r.violationType === "right_user")
                .length === 0 ? (
                <div className='col-md-12'>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2>No violations to be displayed</h2>
                  </div>
                </div>
              ) : (
                <>
                  <PieChart
                    RightUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "right_user"
                      ).length
                    }
                    WrongUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "wrong_user"
                      ).length
                    }
                    MultipleUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "multiple_users"
                      ).length
                    }
                    PhoneDetected={
                      unpagenatedData.filter(
                        (r) => r.violationType === "phone_detected"
                      ).length
                    }
                    PersonBody={
                      unpagenatedData.filter(
                        (r) => r.violationType === "person_body_detected"
                      ).length
                    }
                    NoUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "no_user"
                      ).length
                    }
                    UnrecognizedFace={
                      unpagenatedData.filter(
                        (r) => r.violationType === "unrecognized_face_detected"
                      ).length
                    }
                    LockedLaptop={
                      unpagenatedData.filter(
                        (r) => r.violationType === "locked_laptop"
                      ).length
                    }
                    LockedCamera={
                      unpagenatedData.filter(
                        (r) => r.violationType === "locked_camera"
                      ).length
                    }
                    BlockedCamera={
                      unpagenatedData.filter(
                        (r) => r.violationType === "blocked_camera"
                      ).length
                    }
                  ></PieChart>
                  <BarChart
                    RightUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "right_user"
                      ).length
                    }
                    WrongUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "wrong_user"
                      ).length
                    }
                    MultipleUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "multiple_users"
                      ).length
                    }
                    PhoneDetected={
                      unpagenatedData.filter(
                        (r) => r.violationType === "phone_detected"
                      ).length
                    }
                    PersonBody={
                      unpagenatedData.filter(
                        (r) => r.violationType === "person_body_detected"
                      ).length
                    }
                    NoUser={
                      unpagenatedData.filter(
                        (r) => r.violationType === "no_user"
                      ).length
                    }
                    UnrecognizedFace={
                      unpagenatedData.filter(
                        (r) => r.violationType === "unrecognized_face_detected"
                      ).length
                    }
                    LockedLaptop={
                      unpagenatedData.filter(
                        (r) => r.violationType === "locked_laptop"
                      ).length
                    }
                    LockedCamera={
                      unpagenatedData.filter(
                        (r) => r.violationType === "locked_camera"
                      ).length
                    }
                    BlockedCamera={
                      unpagenatedData.filter(
                        (r) => r.violationType === "blocked_camera"
                      ).length
                    }
                  ></BarChart>
                </>
              )
            ) : (
              <>
                {" "}
                <table className='table text-center'>
                  <thead className='thead-light font-weight-bold'>
                    <tr>
                      <td>NT</td>
                      <td>Password</td>
                      {/* <td>Email</td>
                      <td>Manager Email</td> */}
                      <td>Violation Type</td>
                      <td>Machine Name</td>
                      <td>Violation Date</td>
                      <td>location</td>
                      <td>Image Name</td>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log("searched data log", data)} */}
                    {data &&
                      data?.map((violation, i) => (
                        <tr key={violation.violationId} className='invoice'>
                          <td>{violation.nt}</td>
                          <td>{violation.password}</td>
                          <td>
                            {violation.violationType === "right_user" ? (
                              <p className='statusone p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "wrong_user" ? (
                              <p className='approved-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "multiple_users" ? (
                              <p className='rejected-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "phone_detected" ? (
                              <p className='phone-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType ===
                              "person_body_detected" ? (
                              <p className='inprogress-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "no_user" ? (
                              <p className='assigned-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType ===
                              "unrecognized_face_detected" ? (
                              <p className='unrecognized-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "locked_laptop" ? (
                              <p className='locked-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "locked_camera" ? (
                              <p className='locked-camera-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : violation.violationType === "blocked_camera" ? (
                              <p className='locked-camera-status p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            ) : (
                              <p className=' p-1'>
                                {_.startCase(violation.violationType)}
                              </p>
                            )}
                          </td>

                          <td className='amount'>{violation.machineName}</td>

                          <td className='customer'>
                            {new Date(
                              violation.violationDate
                            ).toLocaleDateString()}
                          </td>

                          <td className='amount'>{violation.location}</td>
                          <td className='amount'>{violation.imageName}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  pageCount={Math.ceil(totalCount / pageSize)}
                  onPageChange={handlePaginationClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
