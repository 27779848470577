import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function PieChart(props) {
  const [data, setdata] = useState([
    {
      violation: "Right user",
      value: props.RightUser,
      color: am4core.color("#3cb13c"),
    },
    {
      violation: "Wrong user",
      value: props.WrongUser,
      color: am4core.color("#8fbc8f"),
    },
    {
      violation: "Multiple users",
      value: props.MultipleUser,
      color: am4core.color("#f00d0d"),
    },
    {
      violation: "Phone detected",
      value: props.PhoneDetected,
      color: am4core.color("#4b0909"),
    },
    {
      violation: "Person body detected",
      value: props.PersonBody,
      color: am4core.color("#ffbb00"),
    },
    {
      violation: "No user",
      value: props.NoUser,
      color: am4core.color("#ff6600"),
    },
    {
      violation: "Unrecognized face detected",
      value: props.UnrecognizedFace,
      color: am4core.color("#12668d"),
    },
    {
      violation: "Locked laptop",
      value: props.LockedLaptop,
      color: am4core.color("#7c060f"),
    },
    {
      violation: "Locked camera",
      value: props.LockedCamera,
      color: am4core.color("#1a0808"),
    },
    {
      violation: "Blocked camera",
      value: props.BlockedCamera,
      color: am4core.color("#1a0558"),
    },
  ]);
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.data = data;
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "violation";
    pieSeries.slices.template.propertyFields.fill = "color";
    chart = chart;

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);
  return (
    <div className='col-md-12 col-sm-12'>
      <div id='chartdiv' style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default PieChart;
