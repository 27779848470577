import jwtDecode from "jwt-decode";

// import { setJwt } from "./httpService";

const tokenKey = "msal.035a3fe2-946e-48d1-9853-7f240b2ea9e6.idtoken";

// setJwt(getJwt());

export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
};

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export const logout = () => {
  localStorage.clear();
};

export default {
  getCurrentUser,
  getJwt,
  logout,
};
