import React from "react";

const Home = () => {
  return (
    <div className='container'>
      <h1>Welcome to face recognition web portal</h1>
    </div>
  );
};

export default Home;
