import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Tabel3 from "../components/machineCounters/table3";
import NavBar from "../components/navBar";
import IdleTimer from "../helpers/IdleTimer";
import { logout } from "../services/authService";

function MachineCounters() {
  const history = useHistory();
  const loc = useLocation();
  useEffect(() => {
    console.log(loc.state.nt);
    const timer = new IdleTimer({
      timeout: 900, //expire after 15 minutes : 900 seconds
      onTimeout: () => {
        history.push("/");
        logout();
        // setIsTimeout(true);
        // alert("stoppp");
      },
      onExpired: () => {
        // do something if expired on load
        history.push("/");
        logout();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);
  return (
    <div className='container-fluid bg-light'>
      <div className='row'>
        <NavBar />
      </div>
      <div className='row justify-content-center d-flex align-items-center'>
        <Tabel3 nt={loc.state.nt}></Tabel3>
      </div>
    </div>
  );
}

export default MachineCounters;
