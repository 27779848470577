import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import "../table/table.css";
import ReactPaginate from "react-paginate";
import jwt from "jsonwebtoken";
import raw from "raw.macro";
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaFileCsv,
  FaLock,
  FaUnlock,
  FaTrash,
} from "react-icons/fa";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { indexOf } from "lodash";
function Tabel3({ nt }) {
  const history = useHistory();
  const [unpagenatedData, setUnpagenatedData] = useState([]);
  //all paginated data
  const [recordID, setRecordId] = useState();
  const [isLockedID, setIsLockedID] = useState();

  const [statsPage, setStatsPage] = useState(false);

  const [isActive, setIsActive] = useState(0);
  const [inActive, setInActive] = useState(0);

  const [isLocked, setIsLocked] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const searchRef = useRef("");
  const selectRef = useRef("");
  const privateKey = raw(`../table/private.json`);
  const [state] = useState({
    email: "webPortal@vodafone.com",
  });
  var token = jwt.sign(state, privateKey);
  // console.log("tokeen", token);
  const headers = {
    authorization: token,
  };
  useEffect(() => {
    // axios to get all
    // setData([]);
    console.log("nt", nt);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinecounter/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: pageNumber,
          pageCount: pageSize,
          NT: nt,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log("first api call", res.data);
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
        setIsLoading(false);
      });
  }, []);
  let violationType = [];

  violationType = [
    { value: "", label: "all" },
    { value: true, label: "Is Active" },
    { value: false, label: "Is Inactive" },
  ];
  const handleClearFilters = () => {
    searchRef.current.value = "";
    selectRef.current.value = "";
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinecounter/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: 0,
          pageCount: pageSize,
          NT: nt,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };

  const handleSearch = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinecounter/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: 0,
          pageCount: pageSize,
          NT: nt,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };

  const handlePaginationClick = (e) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinecounter/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: e.selected,
          pageCount: pageSize,
          NT: nt,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };
  if (isLoading) {
    return (
      <div>
        <div className='spinner-grow text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-secondary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-success' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-danger' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-warning' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-info' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-dark' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-light' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <>
      {/* <NavBar role='admin' /> */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row ml-3 searchRectangle'>
              <div
                className='col-md-6 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  NT Code
                </label>
                <input
                  type='text'
                  className=' filter-text p-2'
                  // onChange={(event) => handleSearchFilter(event)}
                  autoComplete='on'
                  style={{ height: "2.3rem" }}
                  ref={searchRef}
                />
              </div>

              <div className='col-md-12 pt-5'>
                <div className='row'>
                  <label
                    style={{
                      opacity: "0.7",
                      fontWeight: "bold",

                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={handleClearFilters}
                    className='col-md-6'
                  >
                    Clear Filters
                  </label>
                  <div
                    className='col-md-6'
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <button
                      style={{
                        fontWeight: "bold",
                        width: "30%",
                        background: "red",
                        color: "white",

                        borderRadius: "10px",
                      }}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className='tableclass'>
          <div className='row mb-3 mt-3 ml-3 '>
            <h2 className='col-md-6'>Users Counters</h2>
            <div
              className='col-md-6'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <button
                type='button'
                onClick={() => history.push("/machinestatus")}
                className='btn btn-outline-dark mr-3'
              >
                Back to status
              </button>
            </div>
          </div>
          <div className='row mt-3 ml-3 mr-3'>
            <>
              {" "}
              <table className='table text-center'>
                <thead className='thead-light font-weight-bold'>
                  <tr>
                    <td>NT</td>
                    <td>Machine Name</td>
                    <td>Total Count</td>
                    <td>Violation Count</td>
                    <td>Percentage</td>
                    <td>Date</td>
                  </tr>
                </thead>
                <tbody>
                  {/* {console.log("searched data log", data)} */}
                  {data.map((machine, i) => (
                    <tr key={machine._id} className='invoice'>
                      <td>{machine.nt}</td>
                      <td>{machine.machineName}</td>

                      <td className='amount'>{machine.totalImagesCount}</td>

                      <td className='amount'>{machine.violationImagesCount}</td>
                      <td className='amount'>
                        {(machine.violationImagesCount /
                          machine.totalImagesCount) *
                          100}
                        %
                      </td>
                      <td className='amount'>
                        {new Date(machine.loggedDate).toDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                pageCount={Math.ceil(totalCount / pageSize)}
                onPageChange={handlePaginationClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tabel3;
