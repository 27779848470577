import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
function BarChart(props) {
  const [data, setdata] = useState([
    {
      violation: "Right user",
      value: props.RightUser,
    },
    {
      violation: "Wrong user",
      value: props.WrongUser,
    },
    {
      violation: "Multiple users",
      value: props.MultipleUser,
    },
    {
      violation: "Phone detected",
      value: props.PhoneDetected,
      color: am4core.color("#4b0909"),
    },
    {
      violation: "Person body detected",
      value: props.PersonBody,
    },
    {
      violation: "No user",
      value: props.NoUser,
    },
    {
      violation: "Unrecognized face detected",
      value: props.UnrecognizedFace,
    },
    {
      violation: "Locked laptop",
      value: props.LockedLaptop,
    },
    {
      violation: "Locked camera",
      value: props.LockedCamera,
    },
    {
      violation: "Blocked camera",
      value: props.BlockedCamera,
    },
  ]);
  useEffect(() => {
    let chart = am4core.create("barchartdiv", am4charts.XYChart);
    chart.data = data;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "violation";
    categoryAxis.title.text = "violation";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "# of Violations";
    chart.colors.list = [
      am4core.color("#3cb13c"),
      am4core.color("#8fbc8f"),
      am4core.color("#f00d0d"),
      am4core.color("#4b0909"),
      am4core.color("#ffbb00"),
      am4core.color("#ff6600"),
      am4core.color("#12668d"),
      am4core.color("#7c060f"),
      am4core.color("#1a0808"),
    ];
    var Series = chart.series.push(new am4charts.ColumnSeries());
    Series.dataFields.valueY = "value";
    Series.dataFields.categoryX = "violation";
    Series.columns.template.adapter.add("fill", (fill, target) => {
      return target.dataItem
        ? chart.colors.getIndex(target.dataItem.index)
        : fill;
    });

    chart = chart;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);
  return (
    <div className='col-md-12 col-sm-12'>
      <div id='barchartdiv' style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default BarChart;
