import React from "react";
import { NavLink, Link, useHistory } from "react-router-dom";

const NavBar = () => {
  const history = useHistory();
  const handleLogout = () => {
    localStorage.removeItem(
      "msal.035a3fe2-946e-48d1-9853-7f240b2ea9e6.idtoken"
    );
    history.push("/");
  };
  return (
    <div className='col-md-12'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light row'>
        <Link className='navbar-brand col-md-9'>
          <img
            className=' mb-2'
            style={{ width: "8rem", height: "4rem", marginRight: "2rem" }}
            alt='login-background'
            src='/assets/logo2.png'
          ></img>
          <label style={{ fontSize: "28px", fontWeight: "bold" }}>
            Face Recognition
          </label>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>
        <div
          className='collapse navbar-collapse'
          id='navbarNavAltMarkup'
          style={{ display: "flex !important", justifyContent: "center" }}
        >
          <div className='navbar-nav'>
            <>
              <div
                style={{ cursor: "pointer" }}
                className='nav-item nav-link'
                onClick={handleLogout}
              >
                Logout
              </div>
            </>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
