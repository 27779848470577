import React from "react";

import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority:
        "https://login.microsoftonline.com/68283f3b-8487-4c86-adb3-a5228f18b893",
      clientId: "035a3fe2-946e-48d1-9853-7f240b2ea9e6",
      postLogoutRedirectUri: "https://securewfh-vois.vodafone.com/",
      redirectUri: "https://securewfh-vois.vodafone.com/violations",
      validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false,
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
      logger: new Logger((logLevel, message, containsPii) => {}, {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
      }),
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: ["openid"],
  },
  {
    loginType: LoginType.Redirect,
    //     // When a token is refreshed it will be done by loading a page in an iframe.
    //     // Rather than reloading the same page, we can point to an empty html file which will prevent
    //     // site resources from being loaded twice.
    //     tokenRefreshUri: window.location.origin + "/auth.html"
  }
);
