import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import "../table/table.css";
import ReactPaginate from "react-paginate";
import jwt from "jsonwebtoken";
import raw from "raw.macro";
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaFileCsv,
  FaLock,
  FaUnlock,
  FaTrash,
  FaEye,
} from "react-icons/fa";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import { useHistory } from "react-router-dom";
import { indexOf } from "lodash";
function Tabel2() {
  const history = useHistory();
  const [unpagenatedData, setUnpagenatedData] = useState([]);
  //all paginated data
  const [recordID, setRecordId] = useState();
  const [isLockedID, setIsLockedID] = useState();

  const [statsPage, setStatsPage] = useState(false);

  const [isActive, setIsActive] = useState(0);
  const [inActive, setInActive] = useState(0);

  const [isLocked, setIsLocked] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const searchRef = useRef("");
  const selectRef = useRef("");
  const privateKey = raw(`../table/private.json`);
  const [state] = useState({
    email: "webPortal@vodafone.com",
  });
  var token = jwt.sign(state, privateKey);
  // console.log("tokeen", token);
  const headers = {
    authorization: token,
  };
  useEffect(() => {
    // axios to get all
    // setData([]);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}configurations/locked`, {
        headers: headers,
      })
      .then((res) => {
        setIsLocked(res.data[0].isLocked);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: pageNumber,
          pageCount: pageSize,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log("first api call", res.data);
        setData(res?.data?.list);
        setTotalCount(res?.data.paging?.totalCount);
        setPageNumber(res?.data.paging?.nextPageNumber);
      });

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/unpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log("second api call", res.data);
        setUnpagenatedData(res?.data);
        setIsActive(res?.data?.filter((r) => r?.isActive).length);
        setInActive(res?.data?.filter((r) => r?.isActive == false)?.length);

        setIsLoading(false);
      });
  }, []);
  let violationType = [];

  violationType = [
    { value: "", label: "all" },
    { value: true, label: "Is Active" },
    { value: false, label: "Is Inactive" },
  ];
  const handleClearFilters = () => {
    searchRef.current.value = "";
    selectRef.current.value = "";
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: 0,
          pageCount: pageSize,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/unpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log(res.data);
        setUnpagenatedData(res?.data);
        setIsActive(res?.data?.filter((r) => r?.isActive)?.length);
        setInActive(res?.data?.filter((r) => r?.isActive == false)?.length);
      });
  };
  const downloadExcel = () => {
    const data = unpagenatedData?.map((record) => {
      let obj = {};
      for (let key in record) {
        if (_.startCase(key) != "Id") {
          obj[_.startCase(key)] = record[key];
        }
      }

      return obj;
    });
    const ws = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
    const wb = (XLSX.WorkBook = XLSX.utils.book_new());
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // var staffId = +localStorage.getItem('StaffId');

    /* save to file */
    XLSX.writeFile(wb, "Machines_Status_" + new Date() + ".xlsx");
  };
  const handleSearch = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: 0,
          pageCount: pageSize,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res?.data?.list);
        setTotalCount(res?.data?.paging?.totalCount);
        setPageNumber(res?.data?.paging?.nextPageNumber);
      });

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/unpaginated`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // console.log(res.data);
        setUnpagenatedData(res?.data);
        setIsActive(res?.data?.filter((r) => r?.isActive)?.length);
        setInActive(res?.data?.filter((r) => r?.isActive == false)?.length);
      });

    const data = unpagenatedData?.map((record) => {
      let obj = {};
      for (let key in record) {
        obj[_.startCase(key)] = record[key];
      }

      return obj;
    });
  };
  const editLockConfig = (value) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}configurations/edit`,
        { isLocked: value },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setIsLocked(value);
      });
  };
  const handlePaginationClick = (e) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}machinestatus/`,
        {
          searchText: searchRef.current.value,
          Type: selectRef.current.value,
          page: e.selected,
          pageCount: pageSize,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res?.data?.list);
        setTotalCount(res?.data?.paging?.totalCount);
        setPageNumber(res?.data?.paging?.nextPageNumber);
      });
  };
  if (isLoading) {
    return (
      <div>
        <div className='spinner-grow text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-secondary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-success' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-danger' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-warning' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-info' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-dark' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
        <div className='spinner-grow text-light' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        class='modal fade'
        id={"Verify"}
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-lg' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Verfying Lock/Unlock Config
              </h5>
            </div>
            <div class='modal-body row'>
              <div className='col-md-12'>
                <h2>
                  Are you sure You want to change config to{" "}
                  {isLocked ? "Unlocked" : "Locked"}{" "}
                </h2>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                className='btn btn-success'
                data-dismiss='modal'
                onClick={
                  isLocked
                    ? () => {
                        editLockConfig(false);
                      }
                    : () => {
                        editLockConfig(true);
                      }
                }
              >
                Accept
              </button>
              <button type='button' class='btn btn-danger' data-dismiss='modal'>
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class='modal fade'
        id={"Delete"}
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-lg' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Verfying Lock/Unlock Config
              </h5>
            </div>
            <div class='modal-body row'>
              <div className='col-md-12'>
                <h2>Are you sure, You want to Delete this record ?</h2>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                className='btn btn-success'
                data-dismiss='modal'
                onClick={() => {
                  axios
                    .post(
                      `${process.env.REACT_APP_SERVER_URL}machinestatus/deletemachinestatus`,
                      {
                        id: recordID,
                      },
                      {
                        headers: headers,
                      }
                    )
                    .then((res) => {
                      handleClearFilters();
                      setRecordId("");
                    });
                }}
              >
                Accept
              </button>
              <button type='button' class='btn btn-danger' data-dismiss='modal'>
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <NavBar role='admin' /> */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row ml-3 searchRectangle'>
              <div
                className='col-md-6 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  NT Code
                </label>
                <input
                  type='text'
                  className=' filter-text p-2'
                  // onChange={(event) => handleSearchFilter(event)}
                  autoComplete='on'
                  style={{ height: "2.3rem" }}
                  ref={searchRef}
                />
              </div>
              <div
                className='col-md-6 col-sm-12 d-flex'
                style={{ flexDirection: "column" }}
              >
                <label
                  style={{
                    opacity: "0.7",
                    fontWeight: "bold",
                    marginBottom: "0.25",
                  }}
                >
                  Status
                </label>
                <select
                  ref={selectRef}
                  className='filter-text '
                  style={{ padding: "0.35rem " }}
                >
                  {violationType.map((r) => (
                    <option value={r.value}>{r.label}</option>
                  ))}
                </select>
              </div>{" "}
              <div className='col-md-12 pt-5'>
                <div className='row'>
                  <label
                    style={{
                      opacity: "0.7",
                      fontWeight: "bold",

                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={handleClearFilters}
                    className='col-md-6'
                  >
                    Clear Filters
                  </label>
                  <div
                    className='col-md-6'
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <button
                      style={{
                        fontWeight: "bold",
                        width: "30%",
                        background: "red",
                        color: "white",

                        borderRadius: "10px",
                      }}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className='row Counters'>
          <div className='backgroundOne col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaCheckCircle class='material-icons-requests'></FaCheckCircle>
              </div>

              <div className='col-md-7'>
                <p className='number'>{isActive}</p>
                <p className='canceled-requests'>Active</p>
              </div>
            </div>
          </div>
          <div className='backgroundTwo col-md-2 mr-2'>
            <div className='row'>
              <div className='icon col-md-5'>
                <FaExclamationTriangle class='material-icons-requests'></FaExclamationTriangle>
              </div>

              <div className='col-md-7'>
                <p className='number'>{inActive}</p>
                <p className='canceled-requests'>Inactive</p>
              </div>
            </div>
          </div>
        </div>
        <div className='tableclass'>
          <div className='row mb-3 mt-3 ml-3 '>
            <h2 className='col-md-6'>Users Status</h2>
            <div
              className='col-md-6'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <button
                type='button'
                onClick={() => history.push("/violations")}
                className='btn btn-outline-dark mr-3'
              >
                Back to Violations
              </button>
              {isLocked ? (
                <button
                  type='button'
                  data-target={"#Verify"}
                  data-toggle={"modal"}
                  rel='noreferrer'
                  target='_blank'
                  className='btn btn-outline-dark mr-2'
                >
                  <FaUnlock className='mr-2'></FaUnlock>
                  Change config to UnLocked
                </button>
              ) : (
                <button
                  type='button'
                  data-target={"#Verify"}
                  data-toggle={"modal"}
                  rel='noreferrer'
                  target='_blank'
                  className='btn btn-outline-dark mr-2'
                >
                  <FaLock className='mr-2'></FaLock>
                  Change config to Locked
                </button>
              )}
              <button
                type='button'
                onClick={downloadExcel}
                className='btn btn-outline-dark mr-3'
              >
                <FaFileCsv className='mr-2'></FaFileCsv>
                Export Machine Status
              </button>
            </div>
          </div>
          <div className='row mt-3 ml-3 mr-3'>
            <>
              {" "}
              <table className='table text-center'>
                <thead className='thead-light font-weight-bold'>
                  <tr>
                    <td>NT</td>
                    <td>Password</td>
                    {/* <td>Email</td>
                      <td>Manager Email</td> */}

                    <td>Machine Name</td>
                    <td>Is Active</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {/* {console.log("searched data log", data)} */}
                  {data &&
                    data?.map((machine, i) => (
                      <tr key={machine.id} className='invoice'>
                        {console.log("maaaaa", machine)}
                        <td>{machine.nt}</td>
                        <td>{machine.password}</td>

                        <td className='amount'>{machine.machineName}</td>

                        <td className='amount'>
                          {machine.isActive ? "Yes" : "No"}
                        </td>
                        <td>
                          <button
                            type='button'
                            data-target={"#Delete"}
                            data-toggle={"modal"}
                            rel='noreferrer'
                            target='_blank'
                            className='btn btn-outline-dark mr-2'
                            onClick={() => setRecordId(machine.id)}
                          >
                            <FaTrash style={{ color: "#e60000" }}></FaTrash>{" "}
                          </button>
                          <button
                            type='button'
                            rel='noreferrer'
                            className='btn btn-outline-dark mr-2'
                            // onClick={() => history.push("/machinecounter")}
                            onClick={() => {
                              history.push("/machinecounter", {
                                nt: machine.nt,
                              });
                            }}
                          >
                            <FaEye style={{ color: "#e60000" }}></FaEye>{" "}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                pageCount={Math.ceil(totalCount / pageSize)}
                onPageChange={handlePaginationClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tabel2;
