import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

// import NavBar from "./components/navBar";

// import ProtectedRoute from "./components/common/protectedRoute";

// import { getCurrentUser } from "./services/authService";

import "./App.css";
import Login from "./components/login/login";
import Home from "./components/home";
import ViolationsScreen from "./screens/violationsScreen";
import ProtectedRoute from "./components/common/protectedRoute";
import MachineStatusScreen from "./screens/machineStatus";
import MachineCounters from "./screens/machineCounters";

function App() {
  // const [user, setUser] = useState({});
  // useEffect(() => {
  //   const user = getCurrentUser();
  //   setUser(user);
  // }, []);

  return (
    <>
      {/* <ToastContainer /> */}
      {/* <NavBar user={user} /> */}

      {/* <main className='container'> */}
      <Switch>
        <ProtectedRoute
          path='/violations'
          component={ViolationsScreen}
        ></ProtectedRoute>
        <ProtectedRoute path='/home' component={Home}></ProtectedRoute>
        <ProtectedRoute
          path='/violations'
          component={ViolationsScreen}
        ></ProtectedRoute>
        <ProtectedRoute
          path='/machinestatus'
          component={MachineStatusScreen}
        ></ProtectedRoute>

        <ProtectedRoute
          path='/machinecounter'
          component={MachineCounters}
        ></ProtectedRoute>
        <Route path='/' exact component={Login}></Route>
        {/* <ProtectedRoute path='/' component={Home}></ProtectedRoute> */}
      </Switch>
      {/* </main> */}
    </>
  );
}

export default App;
