import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NavBar from "../components/navBar";
import Table from "../components/table/table";
import IdleTimer from "../helpers/IdleTimer";
import { logout } from "../services/authService";

function ViolationsScreen() {
  // const [products, setProducts] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 900, //expire after 15 minutes : 900 seconds
      onTimeout: () => {
        history.push("/");
        logout();
        // setIsTimeout(true);
        // alert("stoppp");
      },
      onExpired: () => {
        // do something if expired on load
        history.push("/");
        logout();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  return (
    <div className='container-fluid bg-light'>
      <div className='row'>
        <NavBar />
      </div>
      <div className='row justify-content-center d-flex align-items-center'>
        <Table />
      </div>
    </div>
  );
}

export default ViolationsScreen;
