import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AzureAD, LoginType, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../services/authProvider";
import "./login.css";
import axios from "axios";
import raw from "raw.macro";
import jwt from "jsonwebtoken";

const Login = () => {
  const history = useHistory();
  const handleLogin = () => {
    history.push("/violations");
  };
  const options = authProvider.getProviderOptions();
  options.loginType = LoginType.Redirect;
  authProvider.setProviderOptions(options);

  const privateKey = raw(`../table/private.json`);
  const [state] = useState({
    email: "webPortal@vodafone.com",
  });
  var token = jwt.sign(state, privateKey);
  // console.log("tokeen", token);
  const headers = {
    authorization: token,
  };

  useEffect(() => {
    var token = jwt.sign(state, privateKey);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}Configurations/Locked/`, {
        headers: headers,
      })
      .then((res) => {
        console.log("test api call with db", res);
      })
      .catch((err) => console.log("test api call with db err for test", err));
  }, []);

  return (
    <AzureAD provider={authProvider}>
      {({ login, logout, authenticationState, error }) => {
        const isInProgress =
          authenticationState === AuthenticationState.InProgress;
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;
        const isUnauthenticated =
          authenticationState === AuthenticationState.Unauthenticated;
        if (isAuthenticated) {
          return (
            <React.Fragment>
              {history.push("/violations")}
              <button onClick={logout} className='Button'>
                Logout
              </button>
            </React.Fragment>
          );
        } else if (isUnauthenticated) {
          return (
            <div className=' vodafone-image '>
              {/* {error ? (
                <h1 className=' focus-in-contract-bck text-white mb-5 login-title '>
                  <span>
                    Apologies, you are not authorized to join this event
                  </span>
                </h1>
              ) : (
                <> */}
              <div className='maindiv row'>
                <img
                  src='/assets/vod-back1.jpg'
                  className='mentor-card-screen-background'
                  alt=''
                />
                <div className='col-md-3'></div>
                <div className='subParentDiv col-md-6 col-xs-12'>
                  <div className='parent text-center'>
                    <img
                      className='logoContainer mb-2'
                      alt='login-background'
                      src='/assets/logo1.png'
                    ></img>
                    <h1 style={{ color: "white", letterSpacing: "0.22rem" }}>
                      Welcome to Face Recognition
                    </h1>
                    <div>
                      <button
                        type='button'
                        onClick={login}
                        className='btn  btn-outline-light button-login mt-4  '
                      >
                        <span className='btnText'>Log In </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* </>
              )} */}

              <div className='copyRights'>@2021 VOIS Technology Egypt</div>
            </div>
          );
        }
      }}
    </AzureAD>
  );
};

export default Login;
